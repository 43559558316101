// assets/js/app.js

require('../css/app.scss');

var $  = require( 'jquery' );
// create global $ and jQuery variables
global.$ = global.jQuery = $;

var $ = require( 'jquery' );
require( 'datatables.net' )( window, $ );
require( 'datatables.net-bs4' )( window, $ );

require( 'bootstrap' );

require( 'select2')( window, $ );